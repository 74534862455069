import React, { useEffect, useLayoutEffect } from 'react';
import { Button, Row, Col, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

const LoginWeb = () => {
  const navigate = useNavigate();
  // catch the query parameters after the user is redirected back from the OAuth page
  useEffect(() => {
    const url = new URL(window.location.href);
    // print all the query parameters
    const urlSplitted = url.href.split('?')[1]?.split('&');
    const refresh_token = urlSplitted
      ?.find((param) => param.includes('refresh_token'))
      ?.split('=')[1];
    const id_token = urlSplitted?.find((param) => param.includes('id_token'))?.split('=')[1];
    const error_message = urlSplitted?.find((param) => param.includes('error'))?.split('=')[1];
    if (refresh_token && id_token) {
      // save the token to local storage
      localStorage.setItem('token', id_token);
      localStorage.setItem('refresh_token', refresh_token);
      // redirect to home page
      navigate('/home');
    } else if (error_message) {
      // show error message
      notification.error({
        message: 'Error',
        description: decodeURIComponent(error_message),
      });
      // redirect to login page
      navigate('/login');
    }
  }, []);
  const handleSignIn = () => {
    // Redirect to OAuth page for sign in
    window.location.href = process.env.REACT_APP_SSO_LOGIN_URL_WEB || '';
  };

  const handleRegister = () => {
    //  open new tab with register url
    window?.open?.(process.env.REACT_APP_SSO_REGISTER_URL, '_blank');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f2f5',
      }}
    >
      <Row justify="center" align="middle" style={{ textAlign: 'center' }}>
        <Col span={24} style={{ marginBottom: '50px' }}>
          <img
            src="/logo192.png"
            alt="CADian Logo"
            style={{ height: '50px', marginRight: '10px' }}
          />
          <h1 style={{ fontSize: '2rem', fontWeight: 600 }}>CADian File Manager</h1>
        </Col>
        <Col span={24}>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <Button
              type="primary"
              size="large"
              onClick={handleSignIn}
              style={{
                backgroundColor: '#f5222d',
                borderColor: '#f5222d',
                borderRadius: '20px',
              }}
            >
              Sign In
            </Button>
            <Button
              size="large"
              onClick={handleRegister}
              style={{
                borderRadius: '20px',
              }}
            >
              Register
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginWeb;
