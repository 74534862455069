import { Layout, theme } from 'antd';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom'; // Import Outlet
import PageHeader from './PageHeader';
import PageSider from './PageSider';
import { useNavigate, useLocation } from 'react-router-dom';
interface IPageLayout {
  // children: React.ReactElement;
}

export default function PageLayout(props: IPageLayout) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location:', location);
  // useEffect(() => {
  //   if (location.key && !location.hash) {
  //     navigate('/dashboard');
  //   }
  // }, [navigate]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <PageHeader />
      <Layout>
        <PageSider />
        <Layout>
          <Layout.Content
            style={{
              padding: 16,
              margin: 0,
              minHeight: 600,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >

            {/* {props.children} */}
            <Outlet />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
