import React, { useContext, useEffect, useState } from 'react';
import {
  Layout,
  Input,
  Avatar,
  Dropdown,
  Space,
  Button,
  Tooltip,
  Card,
  List,
  MenuProps,
} from 'antd';
import { UserOutlined, GlobalOutlined } from '@ant-design/icons';
import { AppContext } from '../components/AppContext';
import { useTranslation } from 'react-i18next';
import { useUpdateUserLangPreference } from '../react-query/mutations/userLang';
import axiosInstance from '../react-query/axiosInstance';
import '../styles/login.css'
import logo from '../images/cadian_simbol.svg'
// import '../styles/reset.css'

const { Header } = Layout;
const { Search } = Input;

const DropDownMenu = () => {
  const { app } = useContext<any>(AppContext);
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
  const [profileImage, setProfileImage] = useState<string | undefined>(undefined);
  const [profileMenuItems, setProfileMenuItems] = useState<MenuProps['items']>([]); // Hold menu items array

  const handleMenuItemClick = async (key: string) => {
    const item = app?.state?.user?.profileMenuOptions?.list?.find(
      (itm: any) => itm.menuName === key,
    );
    if (!item) return;

    if (key === 'Sign Out') {
      await app.logout();
    } else {
      if (app?.state?.platformIsWeb) {
        window.open(item.menuLink, '_blank');
      } else {
        // @ts-ignore
        window.electron.openExternalLink(item.menuLink);
      }
    }
  };

  // Function to create the menu items array
  const getProfileMenuItems = (): MenuProps['items'] => {
    return app?.state?.user?.profileMenuOptions?.list?.map((item: any) => ({
      key: item.menuName,
      label: (
        <Space>
          <img src={item.icon} alt="" />
          {item.menuName}
        </Space>
      ),
      onClick: () => handleMenuItemClick(item.menuName),
    }));
  };

  useEffect(() => {
    if (app?.state?.user?.picture) {
      setProfileImage(app?.state?.user?.picture);
      setUserEmail(app?.state?.user?.email);
    }
    if (app?.state?.user?.profileMenuOptions) {
      setProfileMenuItems(getProfileMenuItems()); // Generate the menu items
    }
  }, [app?.state?.user?.picture, app?.state?.user?.profileMenuOptions]);

  // Dropdown menu that includes the larger avatar and list of menu items
  const dropdownMenu: MenuProps['items'] = [
    {
      key: 'avatar',
      type: 'group',
      label: (
        <Card
          style={{ textAlign: 'center', border: 'none', padding: '0px' }}
          styles={{
            body: {
              padding: '4px',
            },
          }}
        >
          <Avatar size={80} icon={<UserOutlined />} src={profileImage} />
          <p style={{ margin: '0 0', fontWeight: 'bold', color: 'GrayText' }}>{userEmail}</p>
          {/* horizontal line */}
          <hr style={{ margin: '8px 0', border: '0', borderTop: '1px solid #f0f0f0' }} />
        </Card>
      ),
    },
    ...(profileMenuItems || []),
  ];

  return (
    <Dropdown menu={{ items: dropdownMenu }} trigger={['click']} placement="bottomRight" arrow>
      <Avatar
        size={32}
        icon={<UserOutlined />}
        src={profileImage}
        style={{
          cursor: 'pointer',
          minWidth: '32px',
          minHeight: '32px',
        }}

        className='profile-avatar'
      />
    </Dropdown>
  );
};

const PageHeader = () => {
  const { app } = useContext<any>(AppContext);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { i18n, t } = useTranslation();
  const updateLanguage = useUpdateUserLangPreference();

  useEffect(() => {
    if (
      app?.state?.user?.preferedLanguage &&
      app?.state?.user?.preferedLanguage !== i18n.language
    ) {
      i18n.changeLanguage(app?.state?.user?.preferedLanguage);
      axiosInstance.defaults.headers['x-lang'] = i18n.language;
    }
  }, [app?.state?.user?.preferedLanguage]);

  const changeLanguage = async (lng: string) => {
    i18n.changeLanguage(lng);
    axiosInstance.defaults.headers['x-lang'] = i18n.language;
    await updateLanguage.mutateAsync(lng as any);
    if (app?.state?.refetchUserAndUpdateState) {
      await app?.state?.refetchUserAndUpdateState();
    }
    console.log('Language changed to:', lng, i18n);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    if (value.length >= 3) {
      if (window.location.hash !== '#/dashboard' && window.location.hash !== '#/search') {
        window.location.hash = '#/dashboard';
      }
      app.setAppState({ ...app.state, searchTerm: value });
    }
  };

  const menuItems = [
    {
      key: 'kor',
      label: 'KOR',
      onClick: () => changeLanguage('kor'),
      style:
        i18n.language === 'kor'
          ? { fontWeight: 'bold', backgroundColor: '#E43D4F', color: 'white' }
          : {},
    },
    {
      key: 'en',
      label: 'ENG',
      onClick: () => changeLanguage('en'),
      style:
        i18n.language === 'en'
          ? { fontWeight: 'bold', backgroundColor: '#E43D4F', color: 'white' }
          : {},
    },
  ];

  return (
    <Header className='cdam-header'>
          <section className='cdam-header-wrap'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} alt="Logo" style={{ width: '18px', height: '24px', marginRight: '12px' }} />
          <div className='title'>{t('header.title')}
          </div>
        </div>
            <div className='title_sub'>
                {t('header.title_sub')}
            </div>
      </section>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '800px',
        }}
      >
        <div
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}
                className='cdam-header-search'
          >
            <Tooltip
              title={t('header.searchTooltip')}
              open={searchQuery.length > 0 && searchQuery.length < 3}
            >
              <Search
                placeholder={t('header.search')}
                onSearch={handleSearch}
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                className='search-input'
              />
            </Tooltip>

            <Dropdown menu={{ items: menuItems }} trigger={['click']}>
              <Button
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                  color: 'black',
                  padding: '0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                icon={
                  <GlobalOutlined
                    style={{
                      fontSize: '20px',
                      color: '#333',
                      padding: '0',
                    }}
                  />
                }

                className='search-btn'
              />
            </Dropdown>

            <DropDownMenu />
          </div>
        </div>
      </div>
    </Header>
  );
};

export default PageHeader;
