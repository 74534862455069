import { useQuery } from 'react-query';
import axiosClient from '../axiosInstance';
import { useMutation } from 'react-query';
import { notification } from 'antd';

export function useFilesList(folderId: string | null) {
  const query = useQuery(
    ['fileList', folderId],
    async () => {
      const url = folderId ? `file/list/${folderId}` : `file/list/null`;
      const result = await axiosClient.get(url).then((r) => r.data);
      return result.data;
      // return { folders: [], files: [] }
    },
    {
      enabled: folderId !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  return query;
}

export function useGetStarredFilesList(folderId: string | null) {
  const query = useQuery(
    ['starredFileList', folderId],
    async () => {
      const url = folderId ? `file/starred/${folderId}` : `file/starred/null`;
      const result = await axiosClient.get(url).then((r) => r.data);
      return result.data;
    },
    {
      enabled: folderId !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  return query;
}
export function useJobsListLoad(fileIds: string[], fileObjects: any[]) {
  const query = useQuery(
    ['fileStatuses', fileIds],
    async () => {
      if (fileIds.length === 0) {
        return [];
      }
      const url = `jobs/files/${fileIds.join(',')}`;
      const result = await axiosClient.get(url).then((r) => r.data);
      return result.data;
    },
    {
      enabled: fileObjects?.length > 0 && fileIds?.length > 0,
      refetchInterval: 10000, // Refetch every 10 seconds
    },
  );
  return query;
}

// fetch job for a file
export function useJob() {
  const query = useMutation(async (fileId: string) => {
    const url = `jobs/files/${fileId}`;
    const result = await axiosClient.get(url).then((r) => r.data);
    return result.data;
  });
  return query;
}

export function useFilesListLazyLoad() {
  const mutation = useMutation(
    async (folderId: string | null) => {
      const url = folderId ? `file/list/${folderId}` : `file/list/null`;
      const result = await axiosClient.get(url);
      return result.data;
    },
    {
      onError: (error: any) => {
        // show notification
        console.log('error', error);
        notification.error({
          message: 'Error',
          description: error?.message || 'Something went wrong',
        });
      },
    },
  );

  return mutation;
}
export function useJobsLazyLoad() {
  const mutation = useMutation(
    async (fileId: string) => {
      const url = `jobs/file/${fileId}`;
      const result = await axiosClient.get(url);
      return result.data;
    },
    {
      onError: (error: any) => {
        // show notification
        console.log('error', error);
        // notification.error({
        // 	message: 'Error',
        // 	description: error?.message || "Something went wrong",
        // });
      },
    },
  );

  return mutation;
}

export function useGetStarredLazyLoad() {
  const mutation = useMutation(
    async (folderId: string | null) => {
      const url = folderId ? `file/starred/${folderId}` : `file/starred/null`;
      const result = await axiosClient.get(url);
      return result.data;
    },
    {
      onError: (error: any) => {
        // show notification
        console.log('error', error);
        notification.error({
          message: 'Error',
          description: error?.message || 'Something went wrong',
        });
      },
    },
  );

  return mutation;
}
