import { useContext, useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { AppContext } from './components/AppContext';
import { ErrorBoundary } from './components/errorBoundary';
import PageLayout from './layout/PageLayout';
import Dashboard from './views/Dashboard';
import Home from './views/Home';
import Login from './views/Login';
import Trash from './views/Trash';
import ExternalDrivesPage from './views/ExternalDrives';
import GoogleDriveView from './views/GoogleDrive';
import LoginWeb from './views/LoginWeb';
import StarredItems from './views/StarredItems';

export default function App() {
  const { app } = useContext<any>(AppContext);

  useEffect(() => {
    app.checkLoginStatus();
  }, []);
  console.log('Platform:', process.env.REACT_APP_PLATFORM, app?.state);
  const platformIsWeb = process.env.REACT_APP_PLATFORM === 'web';

  return (
    <ErrorBoundary>
      <HashRouter>
        <Routes>
          {platformIsWeb && <Route path="/login" element={<LoginWeb />} />}
          {!platformIsWeb && <Route path="/login" element={<Login />} />}
          <Route path="" element={<PageLayout />}>
            <Route path="home" element={<Home />} />
            <Route path="external-drive" element={<ExternalDrivesPage />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="trash" element={<Trash />} />
            <Route path="google-drive" element={<GoogleDriveView />} />
            <Route path="starred-items" element={<StarredItems />} />
          </Route>
        </Routes>
      </HashRouter>
    </ErrorBoundary>
  );
}
