import { useMutation } from 'react-query';
import axiosInstance from '../axiosInstance';

type MarkStarredPayload = {
    itemId: string;
    itemType: string;
}
export const useMarkStarred = () => {
    const mutation = useMutation(async (items: MarkStarredPayload[]) => {

        const response = await axiosInstance.post('file/mark-starred', { items });
        return response?.data || '';

    },
    {
        onMutate: async (items: MarkStarredPayload[]) => {
            // A mutation is about to happen!
            // Optionally return a context containing data to use when for example rolling back
            return items;
        }
    });

    return mutation;
};

export const useUnMarkStarred = () => {
    const mutation = useMutation(async (items: MarkStarredPayload[]) => {

        const response = await axiosInstance.post('file/unmark-starred', { items });
        return response?.data || '';

    },
    {
        onMutate: async (items: MarkStarredPayload[]) => {
            // A mutation is about to happen!
            // Optionally return a context containing data to use when for example rolling back
            return items;
        }
    });

    return mutation;
}