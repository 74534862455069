// // empty react component

import { useState, useContext, useLayoutEffect, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, notification, Modal, Input } from 'antd';
import { createDirectoryList } from '../utils/common/helpers';
import { FolderAddOutlined, CloudUploadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Loader } from '../components/loader';
import BreadCrumbs from '../components/dashboard/breadCrumbs';
import FileList from '../components/dashboard/fileList';
import { useFilesList, useJob } from '../react-query/queries/fetchFiles';
import { queryClient } from '../react-query/reactQueryClient';
import useUploadFile from '../react-query/mutations/fileUpload';
import { useJobsListLoad } from '../react-query/queries/fetchFiles';
import { AppContext } from '../components/AppContext';
import SearchView from './Search';
import { useLocation } from 'react-router-dom';
import { useCreateFolder } from '../react-query/mutations/postCreateFolder';
import { use } from 'i18next';
import Typography from 'antd/es/typography/Typography';
import Alert from 'antd/es/alert/Alert';

const Dashboard = () => {
  const { app } = useContext<any>(AppContext);
  const { t } = useTranslation();
  const location = useLocation();
  const state = location.state as any;
  const inputRef = useRef(null); // Create a ref for the input element

  const [fileList, setFileList] = useState<Array<any>>([]);

  const getBreadCrumbFromSytemPath = (item: any, itemIncluded: boolean = false) => {
    if (item?.systemPath && item?.systemPath !== '/') {
      const systemPathFolderNames = item.systemPathFolderNames
        .split('/')
        ?.filter((name: any) => name !== '');
      const systemPathFolderIds = item.systemPath.split('/')?.filter((name: any) => name !== '');

      const breadcrumbs = systemPathFolderNames.map((name: any, index: any) => ({
        name,
        id: systemPathFolderIds[index],
      }));
      if (itemIncluded) {
        breadcrumbs.push({ name: item.name, id: item.id });
      }

      return breadcrumbs;
    } else {
      return [];
    }
  };
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>(
    state?.dashboard?.record ? getBreadCrumbFromSytemPath(state?.dashboard?.record, true) : [],
  );
  const [selectedFolderId, setSelectedFolderId] = useState(
    state?.dashboard?.selectedFolderId || null,
  );
  const [isModalVisible, setIsModalVisible] = useState(false); // Control the modal visibility
  const [newFolderName, setNewFolderName] = useState(''); // Store the folder name input
  const { mutateAsync: createFolder, isLoading: folderCreationLoading } = useCreateFolder();
  const [errorMessage, setErrorMessage] = useState('');
  const validateFolderName = (name: string) => {
    const invalidCharacters = /[\/\\?*:<>|"]/; // Regex to check for invalid characters
    const endsWithPeriod = /\.$/;

    if (name.length > 255) {
      setErrorMessage(t('dashboard.folderNameLengthLimit'));
      return false;
    }
    if (invalidCharacters.test(name)) {
      setErrorMessage(t('dashboard.folderNameInvalidCharacters'));
      return false;
    }
    if (endsWithPeriod.test(name)) {
      setErrorMessage(t('dashboard.folderNameEndsWithPeriod'));
      return false;
    }
    setErrorMessage(''); // Clear error if valid
    return true;
  };

  useEffect(() => {
    if (newFolderName) validateFolderName(newFolderName);
  }, [newFolderName]);

  const {
    data: fileListFetched,
    isLoading: isFileListLoading,
    refetch,
  } = useFilesList(selectedFolderId);
  const [isLoading, setIsLoading] = useState(false);
  const uploadFile = useUploadFile();
  const childRef = useRef(null);
  const [fileIdsQuery, setFileIdsQuery] = useState<any>([]);
  const [filesAlreadyProcessed, setFilesAlreadyProcessed] = useState<any>([]);

  // const fileIdsQuery = fileList
  //   ?.filter((file: any) => file.type === 'file' && file.id)
  //   ?.map((file: any) => file.id);
  const { data: statuses, isLoading: isJobLoading } = useJobsListLoad(fileIdsQuery || [], fileList);

  const completedJobs =
    statuses?.jobs?.map((job: any) => ({ id: job.fileId, status: job.status })) || [];
  const isConvertedFileType = (name: string) => {
    return (
      name?.toLowerCase()?.endsWith('.dwg') ||
      name?.toLowerCase()?.endsWith('.dxf') ||
      name?.toLowerCase()?.endsWith('.ifc') ||
      name?.toLowerCase()?.endsWith('.rvt') ||
      name?.toLowerCase()?.endsWith('.dgn')
    );
  };
  useEffect(() => {
    if (isJobLoading) {
      return;
    }
    if (completedJobs?.length) {
      const processedFiles = completedJobs.filter((job: any) => job.status !== 'processing');
      let alreadyProcessedFiles: any = [];
      //  check if any of processedFiles are already processed

      const filesAlreadyProcessedIds = filesAlreadyProcessed.map((job: any) => job.id);
      alreadyProcessedFiles = processedFiles.filter(
        (job: any) => !filesAlreadyProcessedIds.includes(job.id),
      );

      setFileIdsQuery(
        fileList
          ?.filter(
            (file: any) =>
              file.type === 'file' &&
              file.id &&
              !processedFiles?.filter((job: any) => job.id === file.id)?.length &&
              !alreadyProcessedFiles?.filter((job: any) => job.id === file.id)?.length &&
              !filesAlreadyProcessed?.filter((job: any) => job.id === file.id)?.length &&
              isConvertedFileType(file.name),
          )
          ?.map((file: any) => file.id),
      );
      if (alreadyProcessedFiles?.length) {
        setFilesAlreadyProcessed((prev: any) => [...prev, ...alreadyProcessedFiles]);
      }
    } else {
      const fileToQuery = fileList
        ?.filter(
          (file: any) =>
            file.type === 'file' &&
            file.id &&
            !filesAlreadyProcessed?.filter((job: any) => job.id === file.id)?.length &&
            isConvertedFileType(file.name),
        )
        ?.map((file: any) => file.id);
      if (fileToQuery?.length) {
        setFileIdsQuery(fileToQuery);
      }
    }
  }, [fileList, completedJobs]);
  useEffect(() => {
    setIsLoading(true);
    refetch().then(() => {
      setIsLoading(false);
    });
  }, []);

  const onUpdate = async (record: any) => {
    setIsLoading(true);
    await queryClient.invalidateQueries(['fileList', selectedFolderId]);
    setIsLoading(false);
    if (record) {
      await handleFolderClick(record);
    }
  };

  useLayoutEffect(() => {
    const structured = createDirectoryList(fileListFetched);
    setFileList(structured || []);
  }, [fileListFetched]);

  const handleFolderClick = (record: any) => {
    console.log('record', record);
    if (record.type === 'folder') {
      setBreadcrumbs((prev) => [...prev, record]);
      setSelectedFolderId(record.id);
    }
  };

  const handleBreadcrumbClick = (index: number) => {
    if (state?.dashboard) {
      state.dashboard = null;
    }
    if (index < 0) {
      setBreadcrumbs([]);
      setSelectedFolderId(null);
      return;
    }
    const newBreadcrumbs = breadcrumbs.slice(0, index);
    setBreadcrumbs(newBreadcrumbs);
    setSelectedFolderId(newBreadcrumbs[newBreadcrumbs.length - 1].id);
  };

  const handleBackClick = () => {
    if (breadcrumbs.length === 0 && selectedFolderId) {
      // If we're at the root, go back to the root
      setBreadcrumbs([]);
      setSelectedFolderId && setSelectedFolderId(null);
      state.dashboard = null;
    } else if (breadcrumbs.length === 0) {
      // Already at root, do nothing or handle as needed
    } else if (breadcrumbs.length === 1) {
      // If we're going back to the root, we should set the fileList to the original top-level data
      setBreadcrumbs([]);
      setSelectedFolderId(null);
    } else if (breadcrumbs?.length > 1) {
      // If we're navigating back in a subdirectory, handle as before
      const newBreadcrumbs = breadcrumbs.slice(0, -1);
      setBreadcrumbs(newBreadcrumbs);
      setSelectedFolderId(newBreadcrumbs[newBreadcrumbs.length - 1].id);
    }
  };

  const handleFileChangeUpload = async (event: any) => {
    if (event?.target?.files[0]) {
      try {
        await uploadFile.mutateAsync({
          file: event?.target?.files[0],
          folderId: selectedFolderId || 'null',
        });
        event.target.value = null;
        await onUpdate(null);
      } catch (error: any) {
        notification.error({
          message: 'Error',
          description: error?.message || 'Error uploading file',
        });
      }
    }
  };
  const handleFileChangeDragDrop = async (file: any) => {
    if (file) {
      try {
        await uploadFile.mutateAsync({ file, folderId: selectedFolderId || 'null' });
        await onUpdate(null);
      } catch (error: any) {
        console.log('Error uploading file', error);
        notification.error({
          message: 'Error',
          description: error?.message || 'Error uploading file',
        });
      }
    }
  };

  const SUPPORTED_FILE_FORMATS = ['dwg', 'dwt', 'dws', 'dxf', 'dwf', 'dgn', 'ifc', 'rvt'];
  const uniqueTableKey = breadcrumbs.map((crumb) => crumb.name).join('/');
  const handleFileClick = async (record: any) => {
    if (record.type === 'file') {
      console.log('record', record);
      try {
        if (!SUPPORTED_FILE_FORMATS.includes(record.name.toLowerCase().split('.').pop())) {
          notification.error({
            message: '',
            description: t('dashboard.unsupportedFileFormat'),
          });
          return;
        }
        if (app?.state?.platformIsWeb) {
          window.open(`${process.env.REACT_APP_FILE_VIEWER_URL}?fileid=${record.id}`, '_blank');
        } else {
          // @ts-ignore
          window.electron.openJobWindow(
            `${process.env.REACT_APP_FILE_VIEWER_URL}?fileid=${record.id}`,
          );
        }
      } catch (error: any) {
        console.log('Error fetching jobs:', error);
        // notification.error({
        //   message: 'Error',
        //   description: error?.message || 'Error fetching jobs',
        // });
        setIsLoading(false);
      }
    }
  };
  const jobFetch = useJob();

  const openFile = async (record: any) => {
    if (!SUPPORTED_FILE_FORMATS.includes(record.name.toLowerCase().split('.').pop())) {
      notification.error({
        message: '',
        description: t('dashboard.unsupportedFileFormat'),
      });
      return;
    }

    const fileId = record.id || record.fileId || record._id;
    const response = await jobFetch.mutateAsync(fileId);
    if (response?.jobs?.[0]?.status === 'completed') {
      if (app.state.platformIsWeb) {
        window.open(`${process.env.REACT_APP_FILE_VIEWER_URL}?fileid=${fileId}`, '_blank');
      } else {
        // @ts-ignore
        window.electron.openJobWindow(`${process.env.REACT_APP_FILE_VIEWER_URL}?fileid=${fileId}`);
      }
    }
  };

  const showModal = () => {
    setErrorMessage('');
    setIsModalVisible(true); // Show the modal
  };

  const handleOk = async () => {
    setErrorMessage('');

    if (!newFolderName) {
      setErrorMessage(
        (<Alert message={t('dashboard.folderNameRequired')} type={'error'} showIcon />) as any,
      );

      return;
    } else if (!validateFolderName(newFolderName)) {
      return;
    }
    if (newFolderName.trim() === '') {
      notification.error({
        message: t('dashboard.error'),
        description: t('dashboard.folderNameRequired'),
      });
      return;
    }
    setNewFolderName(''); // Clear the folder name input
    setIsModalVisible(false); // Hide the modal after folder creation
    const newFolderCreated = await createFolder({
      folderId: selectedFolderId,
      folderName: newFolderName.trim(),
    });
    console.log('newFolderCreated : ', newFolderCreated);

    await onUpdate({ ...newFolderCreated.data, type: 'folder' });
  };

  const handleKeyDown = (event: any) => {
    setErrorMessage('');

    if (event.key === 'Enter' && newFolderName?.length > 0 && !errorMessage) {
      handleOk(); // Create the folder when "Enter" is pressed
    } else if (event.key === 'Escape') {
      handleCancel(); // Close the modal when "Escape" is pressed
    }
  };
  const handleCancel = () => {
    setErrorMessage('');
    setNewFolderName(''); // Clear the folder name input
    setIsModalVisible(false); // Hide the modal
  };

  // Open modal and take input for folder name, then create folder
  const createNewFolder = () => {
    showModal(); // Show modal
  };
  // useEffect(() => {
  //   if (newFolderName?.length > 0 && inputRef?.current) {
  //     (inputRef.current as any)?.focus(); // Focus the input when a new folder is added
  //   }
  // }, [newFolderName]);

  return app?.state?.searchTerm?.length >= 3 ? (
    <SearchView
      query={app?.state?.searchTerm}
      handleFileClick={openFile}
      handleOpenFolder={(selectedItem: any) => {
        // build breadcrumbs from systemPathFolderNames and systemPathFolderIds
        if (selectedItem?.systemPath && selectedItem?.systemPath !== '/') {
          const breadcrumbs = getBreadCrumbFromSytemPath(selectedItem);
          // breadcrumbs.pop();
          setBreadcrumbs(breadcrumbs);
          setSelectedFolderId(
            breadcrumbs?.length > 0 ? breadcrumbs[breadcrumbs.length - 1].id : null,
          );
          queryClient.clear();
          onUpdate(null);
        } else {
          setBreadcrumbs([]);
          setSelectedFolderId(null);
        }
      }}
    />
  ) : (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Adjusted for space-between */}
        <Loader
          isLoading={
            isFileListLoading || isLoading || uploadFile.isLoading || folderCreationLoading
          }
          children={null}
        />
        <Space style={{ marginBottom: 16 }}>
          <Button onClick={handleBackClick} icon={<ArrowLeftOutlined rev={undefined} />}>
            {t('dashboard.back')}
          </Button>
        </Space>
        <Modal
          title={t('dashboard.createFolder')}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          afterClose={() => {
            // @ts-ignore
            inputRef.current?.focus();
          }}
          afterOpenChange={(open) => {
            if (open) {
              // @ts-ignore
              inputRef.current?.focus();
            }
          }}
        >
          <Space direction="vertical">
            <Input
              ref={inputRef} // Attach the ref
              value={newFolderName}
              placeholder={'folder-name'}
              // onPressEnter={handleOk} // Save the folder on enter key press
              onChange={(e) => setNewFolderName(e.target.value)} // Update the folder name
              style={{ borderColor: '#E43D4F' }}
              onKeyDown={handleKeyDown}
            />
            <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
          </Space>
        </Modal>
        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Space style={{ marginBottom: 16 }}>
            <Button
              onClick={() => document?.getElementById?.('fileInput')?.click()}
              icon={<CloudUploadOutlined rev={undefined} />}
            >
              {t('dashboard.uploadFile')}
            </Button>
            <input
              type="file"
              id="fileInput"
              style={{ display: 'none' }}
              onChange={handleFileChangeUpload}
            />
          </Space>
          <Space style={{ marginBottom: 16 }}>
            <Button
              onClick={(e: any) => {
                // @ts-ignore
                // childRef.current?.addFolder();
                setIsModalVisible(true);
              }}
              icon={<FolderAddOutlined rev={undefined} />}
            >
              {t('dashboard.createFolder')}
            </Button>
            {/* <input
              type="file"
              id="folderInput"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            /> */}
          </Space>
        </div>
      </div>
      <BreadCrumbs
        breadcrumbs={breadcrumbs}
        title={t('dashboard.myDrive')}
        onClick={handleBreadcrumbClick}
      />
      <FileList
        ref={childRef}
        onUpdate={onUpdate}
        key={uniqueTableKey}
        fileList={fileList}
        handleFolderClick={handleFolderClick}
        currentFolderId={selectedFolderId}
        onUpload={handleFileChangeDragDrop}
        handleFileClick={handleFileClick}
        completedJobs={[...completedJobs, ...(filesAlreadyProcessed || [])]}
      />
    </div>
  );
};

export default Dashboard;
