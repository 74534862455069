import { Button, Modal, Space, Empty, Breadcrumb, Menu } from 'antd';
import Table, { ColumnType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { DeleteOutlined, RollbackOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';
import ActionBar from './tableActionBar';
import { Loader } from '../loader';
import { useRestoreTrashItem } from '../../react-query/mutations/restoreTrashItem';
import { useDeleteTrashItem } from '../../react-query/mutations/deleteTrash';
import { FolderIcon, SupportedFileIcon, UnsupportedFileIcon } from '../dashboard/icons/icons';

const FileList = (props: any, ref: any) => {
  const { t, i18n } = useTranslation();
  const { fileList, handleFolderClick, currentFolderId, currentFolder, onUpdate } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState(''); // Add this state to store the selected action
  const { mutateAsync: restoreFolder, isLoading: restoreFolderLoading } = useRestoreTrashItem(
    'folder',
    currentFolderId,
  );
  const { mutateAsync: restoreFile, isLoading: restoreFileLoading } = useRestoreTrashItem(
    'file',
    currentFolderId,
  );
  const { mutateAsync: deleteItem, isLoading: deleteItemLoading } = useDeleteTrashItem();
  const [contextMenu, setContextMenu] = useState<any>(null);

  useEffect(() => {
    if (selectedRowKeys.length === 0) {
      setSelectedAction('');
    }
  }, [selectedRowKeys]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any) => {
      setContextMenu(null);
      if (newSelectedRowKeys?.length > 1) {
        setSelectedRowKeys(newSelectedRowKeys.slice(-1));
      } else {
        setSelectedRowKeys(newSelectedRowKeys);
      }
    },
  };

  const handleDelete = async (selectedRowitem: any) => {
    try {
      await deleteItem({ itemId: selectedRowitem.key, type: selectedRowitem.type });
      setContextMenu(null);
      setSelectedRowKeys([]);
      await onUpdate();
    } catch (error) {
      console.log(error);
      setContextMenu(null);
      setSelectedRowKeys([]);
    }
  };

  const handleRestore = async (selectedRowitem: any) => {
    try {
      const type = selectedRowitem.type;
      if (type === 'folder') {
        await restoreFolder({ itemId: selectedRowitem.key });
      } else {
        await restoreFile({ itemId: selectedRowitem.key });
      }
      setContextMenu(null);
      setSelectedRowKeys([]);
      await onUpdate();
    } catch (error) {
      setContextMenu(null);
      setSelectedRowKeys([]);
    }
  };

  const restoreConfirmation = async (selectedRowitem: any) => {
    const itemName = selectedRowitem.name;
    Modal.confirm({
      title: t('trash.fileList.restoreConfirmationTitle'),
      content: (
        <Trans i18nKey="trash.fileList.restoreConfirmationContent" values={{ itemName }}></Trans>
      ), //t('trash.fileList.restoreConfirmationContent', { itemName }),
      okText: t('trash.fileList.yes'),
      okType: 'primary',
      cancelText: t('trash.fileList.no'),
      onOk() {
        handleRestore(selectedRowitem);
      },
      onCancel() {
        setContextMenu(null);
        setSelectedRowKeys([]);
      },
    });
  };
  // create a function getAction call which returns the action on click of the button
  const handleAction = async (action: string, actionableRow?: any) => {
    const selectedRowitem =
      actionableRow ?? fileList.find((item: any) => item.id === selectedRowKeys[0]);
    if (!selectedRowitem) {
      return;
    }
    switch (action) {
      case 'delete':
        const name = selectedRowitem.name;
        Modal.confirm({
          title: t('trash.fileList.deleteConfirmationTitle'),
          content: (
            <Trans
              i18nKey="trash.fileList.deleteConfirmationContent"
              values={{ name: name }}
            ></Trans>
          ), // t('trash.fileList.deleteConfirmationContent', { name }),
          okText: t('trash.fileList.deleteForever'),
          okType: 'danger',
          cancelText: t('trash.fileList.cancel'),
          onOk() {
            handleDelete(selectedRowitem);
          },
          onCancel() {
            // Do nothing
          },
        });
        break;
      case 'restore':
        restoreConfirmation(selectedRowitem);
        break;
      default:
        break;
    }
  };

  const handleRightClickMenuAction = (action: string) => {
    handleAction(action, { ...contextMenu.record });
  };

  const actionsList = [
    {
      key: 'restore',
      title: t('trash.actionBar.restore'),
      icon: <RollbackOutlined />,
    },
    {
      key: 'delete',
      title: t('trash.actionBar.delete'),
      icon: <DeleteOutlined />,
    },
  ];

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Close the menu if the click occurs outside the context menu
      setContextMenu(null);
    };

    const handleEscKeyPress = (event: any) => {
      // Close the menu when the ESC key is pressed
      if (event.key === 'Escape') {
        setContextMenu(null);
      }
    };

    if (contextMenu) {
      // Add event listeners when the context menu is open
      window.addEventListener('click', handleOutsideClick);
      window.addEventListener('keydown', handleEscKeyPress);
    }

    return () => {
      // Clean up the event listeners when the context menu is closed
      window.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [contextMenu]);

  const handleMenuClick = (key: any) => {
    setSelectedRowKeys([]);
    handleRightClickMenuAction(key);
    setContextMenu(null); // Close the context menu after click
  };

  const onRow = (record: any) => ({
    onContextMenu: (event: any) => {
      event.preventDefault();
      // Prevent the event from propagating so the outside click handler doesn't close it immediately
      event.stopPropagation();
      if (selectedRowKeys.length > 0 && selectedRowKeys[0] !== record.key) {
        setSelectedRowKeys([record.key]);
      }

      setContextMenu({
        record,
        position: { x: event.clientX, y: event.clientY },
      });
    },
  });

  const rightClickMenu = (
    <Menu onClick={(e) => handleMenuClick(e.key)}>
      {actionsList.map((action) => (
        <Menu.Item key={action.key}>
          <Space>
            {action.icon}
            <span>{action.title}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );
  const SUPORTED_FILE_FORMATS = ['dwg', 'dwt', 'dws', 'dxf', 'dwf', 'dgn', 'ifc', 'rvt'];
  const getFileIcon = (name: string) => {
    if (!name) return;
    if (SUPORTED_FILE_FORMATS.includes(name.toLowerCase().split('.').slice(-1).join('.'))) {
      return <SupportedFileIcon />;
    }
    return <UnsupportedFileIcon />;
  };

  const columns: Array<ColumnType<any>> = [
    {
      title: t('trash.fileList.name'),
      showSorterTooltip: false,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <Space>
          {record.type === 'folder' ? <FolderIcon /> : getFileIcon(record.name)}
          <Button
            // disable styling on hover
            style={{ backgroundColor: 'transparent', border: 'none', color: 'black' }}
            type="text"
          >
            {text}
          </Button>
        </Space>
      ),
    },
    {
      title: t('trash.fileList.originalLocation'),
      showSorterTooltip: false,
      dataIndex: 'lastSystemPathFolderNames',
      key: 'lastSystemPathFolderNames',
      sorter: false,
      render: (text, record) => {
        const prefix = 'My Drive';
        if (!text && !currentFolder?.lastSystemPathFolderNames) return prefix;
        else if (!text && currentFolder?.lastSystemPathFolderNames) {
          // find the parent folder and return the path
          if (currentFolder?.lastSystemPathFolderNames?.endsWith('/')) {
            text = currentFolder?.lastSystemPathFolderNames + record?.name + '/';
          } else {
            text = currentFolder?.lastSystemPathFolderNames + '/' + record?.name + '/';
          }
        }
        const pathParts = text.split('/')?.slice
          ? text.split('/').slice(1, text.split('/').length - 2)
          : [];

        const breadCrumbItems = pathParts.map((part: any, index: any) => ({
          title: part,
          key: index,
        }));
        breadCrumbItems.unshift({ title: prefix, key: -1 });
        return (
          <Breadcrumb separator=">" items={breadCrumbItems}>
            {/* <Breadcrumb.Item>{prefix}</Breadcrumb.Item>
            {pathParts.map((part: any, index: any) => (
              <Breadcrumb.Item key={index}>{part}</Breadcrumb.Item>
            ))} */}
          </Breadcrumb>
        );
      },
    },
    {
      title: t('trash.fileList.deletedDate'),
      showSorterTooltip: false,
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      defaultSortOrder: 'descend' as SortOrder,
      sorter: (a, b) => moment(a.deletedAt).unix() - moment(b.deletedAt).unix(),
      render: (text: string) => {
        if (i18n.language === 'en') {
          // return Aug, 12 2021 12:00
          return moment(text)?.local()?.format('MMM DD, YYYY HH:mm');
        } else {
          return moment(text)?.local()?.format('YYYY-MM-DD HH:mm');
        }
      },
    },
    {
      title: t('trash.fileList.format'),
      showSorterTooltip: false,
      dataIndex: 'mimeType',
      key: 'format',
      sorter: (a, b) => a.mimeType?.split('/')[1]?.localeCompare(b.mimeType?.split('/')[1]),
      render: (text, record) => {
        if (!text) return '';
        return record?.name?.split ? record?.name?.split('.').slice(-1).join('.') : '';
      },
    },
    {
      title: t('trash.fileList.size'),
      showSorterTooltip: false,
      dataIndex: 'size',
      key: 'size',
      sorter: (a, b) => a.size - b.size,
      render: (text) => {
        if (!text) return '';
        if (text < 1024) return `${text} B`;
        if (text < 1024 * 1024) return `${(text / 1024).toFixed(2)} KB`;
        if (text < 1024 * 1024 * 1024) return `${(text / (1024 * 1024)).toFixed(2)} MB`;
        return `${(text / (1024 * 1024 * 1024)).toFixed(2)} GB`;
      },
    },
  ];

  return (
    <>
      {selectedRowKeys.length === 1 && (
        <ActionBar
          selectedCount={selectedRowKeys.length}
          setSelectedAction={setSelectedAction}
          selectedAction={selectedAction}
          performAction={handleAction}
        />
      )}
      <Loader
        isLoading={restoreFolderLoading || restoreFileLoading || deleteItemLoading}
        children={null}
      />
      <Table
        dataSource={fileList}
        columns={columns}
        rowSelection={rowSelection}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: false,
          // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          position: ['bottomCenter'],
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>
                  <p>{t('trash.fileList.noData')}</p>
                  <p>{t('trash.fileList.trashInfo')}</p>
                </span>
              }
            />
          ),
        }}
        onRow={onRow}
      />
      {contextMenu && (
        <div
          style={{
            position: 'absolute',
            top: contextMenu.position.y,
            left: contextMenu.position.x,
            zIndex: 1000,
            border: '1px solid #d9d9d9',
            backgroundColor: '#fff',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          }}
        >
          {rightClickMenu}
        </div>
      )}
    </>
  );
};

export default FileList;
