// // empty react component

import { useState, useContext, useLayoutEffect, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, notification } from 'antd';
import { createDirectoryList } from '../utils/common/helpers';
import { FolderAddOutlined, CloudUploadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Loader } from '../components/loader';
import BreadCrumbs from '../components/dashboard/breadCrumbs';
import FileList from '../components/dashboard/fileList';
import { useGetStarredFilesList, useJob } from '../react-query/queries/fetchFiles';
import { queryClient } from '../react-query/reactQueryClient';
import useUploadFile from '../react-query/mutations/fileUpload';
import { useJobsListLoad } from '../react-query/queries/fetchFiles';
import { AppContext } from '../components/AppContext';
import { useNavigate } from 'react-router-dom';
const StarredFilesView = () => {
  const { app } = useContext<any>(AppContext);
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<Array<any>>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const navigate = useNavigate();
  const {
    data: fileListFetched,
    isLoading: isFileListLoading,
    refetch,
  } = useGetStarredFilesList(selectedFolderId);
  const [isLoading, setIsLoading] = useState(false);
  const uploadFile = useUploadFile();
  const childRef = useRef(null);
  const fileIdsQuery = fileList
    ?.filter((file: any) => file.type === 'file' && file.id)
    ?.map((file: any) => file.id);
  const { data: statuses } = useJobsListLoad(fileIdsQuery || [], fileList);

  const completedJobs =
    statuses?.jobs?.map((job: any) => ({ id: job.fileId, status: job.status })) || [];

  useEffect(() => {
    setIsLoading(true);
    refetch().then(() => {
      setIsLoading(false);
    });
  }, []);

  const onUpdate = async () => {
    setIsLoading(true);
    await queryClient.invalidateQueries(['fileList', selectedFolderId]);
    setIsLoading(false);
  };

  useLayoutEffect(() => {
    const structured = createDirectoryList(fileListFetched);
    setFileList(structured || []);
  }, [fileListFetched]);

  const handleFolderClick = (record: any) => {
    if (record.type === 'folder') {
      navigate('/dashboard', {
        state: {
          dashboard: {
            selectedFolderId: record.id,
            record: record,
          },
        },
      });
      // setBreadcrumbs((prev) => [...prev, record]);
      // setSelectedFolderId(record.id);
    }
  };

  const handleBreadcrumbClick = (index: number) => {
    if (index === -1) {
      setBreadcrumbs([]);
      setSelectedFolderId(null);
      return;
    }
    const newBreadcrumbs = breadcrumbs.slice(0, index);
    setBreadcrumbs(newBreadcrumbs);
    setSelectedFolderId(newBreadcrumbs[newBreadcrumbs.length - 1].id);
  };

  const handleBackClick = () => {
    if (breadcrumbs.length === 0 && selectedFolderId) {
      // If we're at the root, go back to the root
      setBreadcrumbs([]);
      setSelectedFolderId && setSelectedFolderId(null);
    } else if (breadcrumbs.length === 0) {
      // Already at root, do nothing or handle as needed
    } else if (breadcrumbs.length === 1) {
      // If we're going back to the root, we should set the fileList to the original top-level data
      setBreadcrumbs([]);
      setSelectedFolderId(null);
    } else if (breadcrumbs?.length > 1) {
      // If we're navigating back in a subdirectory, handle as before
      const newBreadcrumbs = breadcrumbs.slice(0, -1);
      setBreadcrumbs(newBreadcrumbs);
      setSelectedFolderId(newBreadcrumbs[newBreadcrumbs.length - 1].id);
    }
  };

  const handleFileChangeUpload = async (event: any) => {
    if (event?.target?.files[0]) {
      try {
        await uploadFile.mutateAsync({
          file: event?.target?.files[0],
          folderId: selectedFolderId || 'null',
        });
        event.target.value = null;
        await onUpdate();
      } catch (error: any) {
        notification.error({
          message: 'Error',
          description: error?.message || 'Error uploading file',
        });
      }
    }
  };
  const handleFileChangeDragDrop = async (file: any) => {
    if (file) {
      try {
        await uploadFile.mutateAsync({ file, folderId: selectedFolderId || 'null' });
        await onUpdate();
      } catch (error: any) {
        console.log('Error uploading file', error);
        notification.error({
          message: 'Error',
          description: error?.message || 'Error uploading file',
        });
      }
    }
  };

  const SUPPORTED_FILE_FORMATS = ['dwg', 'dwt', 'dws', 'dxf', 'dwf', 'dgn', 'ifc', 'rvt'];
  const uniqueTableKey = breadcrumbs.map((crumb) => crumb.name).join('/');
  const handleFileClick = async (record: any) => {
    if (record.type === 'file') {
      try {
        if (!SUPPORTED_FILE_FORMATS.includes(record.name.toLowerCase().split('.').pop())) {
          notification.error({
            message: '',
            description: t('dashboard.unsupportedFileFormat'),
          });
          return;
        }
        if (app?.state?.platformIsWeb) {
          window.open(`${process.env.REACT_APP_FILE_VIEWER_URL}?fileid=${record.id}`, '_blank');
        } else {
          // @ts-ignore
          window.electron.openJobWindow(
            `${process.env.REACT_APP_FILE_VIEWER_URL}?fileid=${record.id}`,
          );
        }
      } catch (error: any) {
        console.log('Error fetching jobs:', error);
        // notification.error({
        //   message: 'Error',
        //   description: error?.message || 'Error fetching jobs',
        // });
        setIsLoading(false);
      }
    }
  };
  const jobFetch = useJob();

  const openFile = async (record: any) => {
    if (!SUPPORTED_FILE_FORMATS.includes(record.name.toLowerCase().split('.').pop())) {
      notification.error({
        message: '',
        description: t('dashboard.unsupportedFileFormat'),
      });
      return;
    }

    const fileId = record.id || record.fileId || record._id;
    const response = await jobFetch.mutateAsync(fileId);
    if (response?.jobs?.[0]?.status === 'completed') {
      if (app.state.platformIsWeb) {
        window.open(`${process.env.REACT_APP_FILE_VIEWER_URL}?fileid=${fileId}`, '_blank');
      } else {
        // @ts-ignore
        window.electron.openJobWindow(`${process.env.REACT_APP_FILE_VIEWER_URL}?fileid=${fileId}`);
      }
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        {/* Adjusted for space-between */}
        <Loader
          isLoading={isFileListLoading || isLoading || uploadFile.isLoading}
          children={null}
        />
        <Space style={{ marginBottom: 16 }}>
          <Button onClick={handleBackClick} icon={<ArrowLeftOutlined rev={undefined} />}>
            {t('dashboard.back')}
          </Button>
        </Space>
      </div>
      <BreadCrumbs
        breadcrumbs={breadcrumbs}
        title={t('dashboard.myDrive')}
        onClick={handleBreadcrumbClick}
      />
      <FileList
        ref={childRef}
        onUpdate={onUpdate}
        key={uniqueTableKey}
        fileList={fileList}
        handleFolderClick={handleFolderClick}
        currentFolderId={selectedFolderId}
        onUpload={handleFileChangeDragDrop}
        handleFileClick={handleFileClick}
        completedJobs={completedJobs}
        refetchStarredFiles={() => {
          setIsLoading(true);
          refetch().then(() => {
            setIsLoading(false);
          });
        }}
        disableActions={true}
      />
    </div>
  );
};

export default StarredFilesView;
