import { Table, Button, Row, Col, Modal, Card } from 'antd';
import { DropboxOutlined } from '@ant-design/icons';
import { DriveType, DriveTypes } from './types';
import { Loader } from '../loader';
import { Trans, useTranslation } from 'react-i18next';

const StorageConnect = ({
  connectDrive,
  data,
  loading: isLoading,
  deleteAccount,
}: {
  loading: boolean;
  deleteAccount: (driveType: DriveTypes, email: string) => void;
  data: any;
  connectDrive: (driveType: DriveTypes) => void;
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('externalDrives.storageConnect.connectedAccount'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '',
      dataIndex: 'email',
      key: 'selectDrive',
      render: (email: string, record: any) => {
        return (
          <>
            <Button
              disabled={isLoading}
              style={{
                color: 'white',
                backgroundColor: '#E43D4F',
                textTransform: 'uppercase',
                border: 'none',
                fontWeight: 'bold',
              }}
              onClick={() => {
                Modal.confirm({
                  title: t('externalDrives.storageConnect.disconnectConfirmationTitle'),
                  content: (
                    <p>
                      {/* {t('externalDrives.storageConnect.disconnectConfirmationContent', {driveType: 'Google Drive',email})} */}
                      <Trans
                        i18nKey="externalDrives.storageConnect.disconnectConfirmationContent"
                        values={{ driveType: 'Google Drive', email }}
                      ></Trans>
                    </p>
                  ),
                  okText: t('externalDrives.storageConnect.disconnect'),
                  okType: 'danger',
                  cancelText: t('externalDrives.storageConnect.cancel'),
                  width: 500,
                  onOk() {
                    deleteAccount(record.type, record.email);
                  },
                  onCancel() {
                    // Do nothing
                  },
                });
              }}
            >
              {t('externalDrives.storageConnect.remove')}
            </Button>
          </>
        );
      },
    },
  ];

  const googleDriveUsers =
    data?.googleDrive?.emails?.map((email: string, index: number) => {
      return {
        key: 'googleDrive-' + index,
        email,
        type: DriveType.GOOGLE_DRIVE,
      };
    }) || undefined;

  return (
    <div>
      <Loader isLoading={isLoading} children={null} />
      <h3>{t('externalDrives.storageConnect.connectYourCloudStorages')}</h3>
      <hr style={{ marginBottom: '20px' }} />

      <Card key="1">
        <Row align="middle" key={'card-1-r-1'}>
          <Col span={2}>
            <DropboxOutlined style={{ fontSize: '24px', color: '#0061FF' }} />
          </Col>
          <Col span={20}>Dropbox</Col>
          <Col span={2}>
            <Button shape="circle" icon="+" />
          </Col>
        </Row>
        {/* {data?.dropbox?.email && <Table columns={columns} dataSource={data?.dropbox?.email} pagination={false} />} */}
      </Card>

      <Card bordered={false} style={{ marginBottom: '20px' }} key="2">
        <Row align="middle" key={'card-2-r-1'}>
          <Col span={2}>
            <img
              src="googleDriveLogo.png"
              alt=""
              aria-hidden="true"
              role="presentation"
              style={{ height: '24px', width: '24px' }}
            />
          </Col>
          <Col span={20}>Google Drive</Col>
          <Col span={2}>
            <Button shape="circle" icon="+" onClick={() => connectDrive(DriveType.GOOGLE_DRIVE)} />
          </Col>
        </Row>
        <div style={{ marginTop: '20px' }}>
          {googleDriveUsers?.length && (
            <Table columns={columns} dataSource={googleDriveUsers} pagination={false} />
          )}
        </div>
      </Card>

      <Card key="3">
        <Row align="middle" key={'card-3-r-1'} style={{ height: '38px' }}>
          <Col span={2}>
            <img
              src="NAVER_WORKS-Drive.svg"
              alt=""
              aria-hidden="true"
              role="presentation"
              style={{ height: '40px', width: '40px' }}
            />
          </Col>
          <Col span={20}>Naver Works Drive</Col>
          <Col span={2}>
            <Button shape="circle" icon="+" />
          </Col>
        </Row>
      </Card>

      <Card key="4">
        <Row align="middle" key={'card-3-r-1'} style={{ height: '38px' }}>
          <Col span={2}>
            <img
              src="OneDrive.svg"
              alt=""
              aria-hidden="true"
              role="presentation"
              style={{ height: '40px', width: '40px' }}
            />
          </Col>
          <Col span={20}>OneDrive</Col>
          <Col span={2}>
            <Button shape="circle" icon="+" />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default StorageConnect;
