import { Breadcrumb, Space } from 'antd';

const BreadCrumbs = ({
  breadcrumbs,
  title,
  onClick,
}: {
  breadcrumbs: any[];
  title: string;
  onClick: (index: number) => void;
}) => {
  let breadcrumbItems = breadcrumbs.map((crumb, index) => ({
    title: crumb.name,
    key: index + 1, // Generally, using index as a key is not recommended, consider using a unique ID if possible
  }));
  breadcrumbItems.unshift({ title, key: -1 });
  return (
    <Space style={{ marginBottom: 16 }}>
      <Breadcrumb
        separator=">"
        items={breadcrumbItems}
        itemRender={(route, params, routes, paths) => {
          const last = routes.indexOf(route) === routes.length - 1;
          return last ? (
            <span>{route.title}</span>
          ) : (
            <a onClick={() => onClick(parseInt(route.key?.toString() || '-1'))}>{route.title}</a>
          );
        }}
      ></Breadcrumb>
    </Space>
  );
};

export default BreadCrumbs;
