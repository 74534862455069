import { Button, Modal, Space, notification, Input, message, Menu, Typography } from 'antd';
import Table, { ColumnType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import moment from 'moment';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  DeleteOutlined,
  DownloadOutlined,
  CopyOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import {
  RenameIcon,
  MoveFolderIcon,
  ShareIcon,
  FolderIcon,
  SupportedFileIcon,
  UnsupportedFileIcon,
} from './icons/icons';
import { randomUUID } from '../../utils/common/helpers';
import { useCreateFolder } from '../../react-query/mutations/postCreateFolder';
import ActionBar from './tableActionBar';
import { useDeleteItem } from '../../react-query/mutations/delete';
import { Loader } from '../loader';
import DownloadNotification, { DownloadItem } from './downloadNotification';
import { useDropzone } from 'react-dropzone';
import { useRenameItem } from '../../react-query/mutations/rename';
import FolderNavigation from '../../components/dashboard/folderNavigation';
import { useMoveItem } from '../../react-query/mutations/moveFolder';
import { useCopyItem } from '../../react-query/mutations/copyItem';
import { useTranslation, Trans } from 'react-i18next';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { useMarkStarred, useUnMarkStarred } from '../../react-query/mutations/markStarred';

// Custom hook to poll API at intervals
// const usePolling = (callback: any, interval: any) => {
//   useEffect(() => {
//     const polling = setInterval(callback, interval);

//     return () => clearInterval(polling); // Clear interval on component unmount
//   }, [callback, interval]);
// };

const FileList = forwardRef((props: any, ref: any) => {
  const inputRef = useRef(null); // Create a ref for the input element
  const { t, i18n } = useTranslation();
  const {
    fileList,
    handleFolderClick,
    handleFileClick,
    currentFolderId,
    onUpdate,
    onUpload,
    completedJobs,
    refetchStarredFiles,
    disableActions,
  } = props;
  const [data, setData] = useState<any>(fileList || []);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState(''); // Add this state to store the selected action
  const { mutateAsync: deleteFolder, isLoading: deleteFolderLoading } = useDeleteItem(
    'folder',
    currentFolderId,
  );
  const { mutateAsync: deleteFile, isLoading: deleteFileLoading } = useDeleteItem(
    'file',
    currentFolderId,
  );
  const { mutateAsync: renameFolder, isLoading: renameFolderLoading } = useRenameItem(
    'folder',
    currentFolderId,
  );
  const { mutateAsync: renameFile, isLoading: renameFileLoading } = useRenameItem(
    'file',
    currentFolderId,
  );
  const { mutateAsync: postMarkStarred, isLoading: unstarredLoading } = useMarkStarred();
  const { mutateAsync: postUnMarkStarred, isLoading: starredLoading } = useUnMarkStarred();
  // Add state for starred items
  const [starred, setStarred] = useState<any>([]);
  const isStarred = (record: any) => starred.includes(record.key);
  const [hoveredRowKey, setHoveredRowKey] = useState(null); // State to track hovered row

  const { mutateAsync: createFolder, isLoading: folderCreationLoading } = useCreateFolder();
  const [downloads, setDownloads] = useState<DownloadItem[]>([]);
  const [editingKey, setEditingKey] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const [selectOnMove, setSelectOnMove] = useState(false);
  const { mutateAsync: moveFolder, isLoading: moveFolderLoading } = useMoveItem(
    'folder',
    currentFolderId,
  );
  const { mutateAsync: moveFile, isLoading: moveFileLoading } = useMoveItem(
    'file',
    currentFolderId,
  );
  const { mutateAsync: copyFolder, isLoading: copyFolderLoading } = useCopyItem(
    'folder',
    currentFolderId,
  );
  const { mutateAsync: copyFile, isLoading: copyFileLoading } = useCopyItem(
    'file',
    currentFolderId,
  );
  const [contextMenu, setContextMenu] = useState<any>(null);
  const [itemToMove, setItemToMove] = useState<any>(null);

  const onDrop = useCallback((acceptedFiles: any) => {
    onUpload(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });
  const [errorMessage, setErrorMessage] = useState('');
  const validateFolderName = (name: string) => {
    const invalidCharacters = /[\/\\?*:<>|"]/; // Regex to check for invalid characters
    const endsWithPeriod = /\.$/;

    if (name.length > 255) {
      setErrorMessage(t('dashboard.folderNameLengthLimit'));
      return false;
    }
    if (invalidCharacters.test(name)) {
      setErrorMessage(t('dashboard.folderNameInvalidCharacters'));
      return false;
    }
    if (endsWithPeriod.test(name)) {
      setErrorMessage(t('dashboard.folderNameEndsWithPeriod'));
      return false;
    }
    setErrorMessage(''); // Clear error if valid
    return true;
  };

  useEffect(() => {
    if (newFolderName) validateFolderName(newFolderName);
  }, [newFolderName]);

  useEffect(() => {
    // if fileList changes update the starred items
    const starredItems = fileList.filter((item: any) => item.starred === true);
    setStarred(starredItems.map((item: any) => item.key));
  }, [fileList]);

  const isConvertedFileType = (name: string) => {
    return (
      name?.toLowerCase()?.endsWith('.dwg') ||
      name?.toLowerCase()?.endsWith('.dxf') ||
      name?.toLowerCase()?.endsWith('.ifc') ||
      name?.toLowerCase()?.endsWith('.rvt') ||
      name?.toLowerCase()?.endsWith('.dgn')
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any) => {
      // disable context menu record
      setContextMenu(null);
      // disable if already editing
      if (editingKey && newSelectedRowKeys?.length) return;
      // disable if the selected item is a file and is not present in the completed jobs
      if (newSelectedRowKeys.length === 1) {
        const item = data.find((item: any) => item.key === newSelectedRowKeys[0]);
        const currentFileJob = completedJobs.find((job: any) => job.id === item.key);
        if (
          item?.type === 'file' &&
          isConvertedFileType(item?.name) &&
          ['processing', 'waiting'].includes(currentFileJob?.status)
        ) {
          setSelectedRowKeys([]);
          notification.error({ message: t('dashboard.fileList.fileProcessingError'), duration: 2 });
          return;
        }
      }
      if (newSelectedRowKeys?.length > 1) {
        setSelectedRowKeys(newSelectedRowKeys.slice(-1));
      } else if (newSelectedRowKeys.length === 0) {
        setSelectedRowKeys(newSelectedRowKeys);
        // cancel the editing if the selected row is empty
        setEditingKey('');
        setNewFolderName('');
        setSelectedAction('');
        // set the record to not editable
        const newData = [...data];
        newData.forEach((item) => {
          item.editable = false;
        });
        // filter out the temporary folder
        const filteredData = newData.filter((item: any) => item.custom !== true);
        setData(filteredData);
        // cancle any move or copy action
        setSelectOnMove(false);
        setItemToMove(null);
      } else {
        setSelectedRowKeys(newSelectedRowKeys);
      }
    },
  };

  const edit = (record: any) => {
    setErrorMessage('');
    setEditingKey(record.key);
  };

  const cancel = () => {
    setErrorMessage('');

    const item = data.find((item: any) => item.key === editingKey);
    if (!item) return;
    if (!item.custom) {
      const newData = [...data];
      const _item = newData.find((item_) => item.key === item_.key);

      _item.editable = false;
      setData(newData);
    } else if (item.custom === true) {
      // Remove the temporary folder if the user cancels the creation
      setData(data.filter((item: any) => item.key !== editingKey));
    }
    setEditingKey('');
    setNewFolderName('');
  };

  const deleteFileConfirmation = (record: any) => {
    Modal.confirm({
      title: t('dashboard.fileList.deleteConfirmation.title'),
      content: (
        <Trans
          i18nKey="dashboard.fileList.deleteConfirmation.content"
          values={{ name: record.name }}
        ></Trans>
      ),
      // t("dashboard.fileList.deleteConfirmation.content", { name: record.name }),
      okText: t('dashboard.fileList.deleteConfirmation.okText'),
      okType: 'danger',
      cancelText: t('dashboard.fileList.deleteConfirmation.cancelText'),
      onOk() {
        onDelete(record);
      },
      onCancel() {
        // Do nothing
      },
    });
  };

  const onDelete = async (actionableRow: any) => {
    try {
      const isFile = actionableRow?.type === 'file';

      const deleteItem = isFile ? deleteFile : deleteFolder;
      await deleteItem({ itemId: actionableRow.key });
      // queryClient.invalidateQueries(["fileList", currentFolderId]);
      await onUpdate(null);
      setSelectedRowKeys([]);
    } catch (error) {}
  };

  const startDownload = async (url: any, fileName: any) => {
    const newDownload = {
      id: Math.random().toString(36).substr(2, 9), // simple unique ID generator
      fileName,
      progress: 0, // initial progress
    };
    setDownloads((prev) => [...prev, newDownload]);
    let token;
    if (process.env.REACT_APP_PLATFORM === 'web') {
      token = localStorage.getItem('token');
    } else {
      // @ts-ignore
      token = await window.electron.getStoreValue('token');
    }

    // Fetch the file as a Blob
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      // Handle the error
      setDownloads((currentDownloads) =>
        currentDownloads.filter((d) => {
          return d.id !== newDownload.id;
        }),
      );

      notification.error({ message: t('dashboard.fileList.downloadError') });
      return;
    }
    const reader = response?.body?.getReader();
    const contentLength = +(response?.headers?.get?.('Content-Length') || '0') || 0;
    const mimeType = response.headers.get('Content-Type');

    let receivedLength = 0; // received that many bytes at the moment
    let chunks = []; // array of received binary chunks (comprises the body)
    while (true) {
      const { done, value } = (await reader?.read()) as { done: boolean; value: Uint8Array };

      if (done) {
        break;
      }
      chunks.push(value);
      receivedLength += value.length;

      // Update progress in a way to ensure the UI updates
      const newProgress = (receivedLength / contentLength) * 100;

      setDownloads((currentDownloads) =>
        currentDownloads.map((d) => {
          if (d.id === newDownload.id && d.progress < 100) {
            return { ...d, progress: newProgress };
          }
          return d;
        }),
      );
      await new Promise((resolve) => setTimeout(resolve, 0)); // Force React to acknowledge the state update
    }

    const blob = new Blob(chunks);
    const blobWithCorrectType = new Blob([blob], { type: mimeType || undefined });

    const blobUrl = window.URL.createObjectURL(blobWithCorrectType);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(blobUrl); // Clean up
      document.body.removeChild(link);
    }, 100); // Delay slightly to ensure download starts
    setTimeout(() => {
      setDownloads((currentDownloads) =>
        currentDownloads.filter((d) => {
          return d.id !== newDownload.id;
        }),
      );
    }, 5000); // Delay slightly to ensure download starts

    // Optionally hide the notification after a delay
  };

  const downloadFile = async (actionableRow: any) => {
    const fileName = actionableRow?.name;
    const downloadUrl = `${process.env.REACT_APP_API_FILE_SERVER_URL}/file/${actionableRow.key}`;

    await startDownload(downloadUrl, fileName);
  };
  const onDownload = async (actionableRow: any) => {
    const isFile = actionableRow?.type === 'file';
    if (isFile) await downloadFile(actionableRow);
  };

  const save = async (key: any) => {
    const newData = [...data];
    const index = newData.findIndex((item) => key === item.key);
    if (!newFolderName?.length && !newData[index]?.custom) {
      // notification.error({ message: t('dashboard.folderNameError') });
      setErrorMessage(t('dashboard.folderNameError'));
      return;
    }
    if (index > -1) {
      const item = newData[index];
      if (item.custom === true) {
        try {
          newData.splice(index, 1);
          setData(newData);
          setEditingKey('');
          const updatedNewFolderName = newFolderName || 'New Folder';
          setNewFolderName('');
          const newFolderCreated = await createFolder({
            folderId: currentFolderId,
            folderName: updatedNewFolderName,
          });
          await onUpdate({ ...newFolderCreated.data, type: 'folder' });
          // await handleFolderClick();
        } catch (error) {}
      } else {
        try {
          const isFile = item?.type === 'file';
          const renameItem = isFile ? renameFile : renameFolder;
          let shouldUpdate = true;
          if (isFile) {
            //  add the oroiginal extension to the new folder name
            const originalExtension = item?.name.split('.').slice(-1).join('.');
            const updatedNewFolderName = `${newFolderName}.${originalExtension}`;
            // if user presses enter without changing the name
            if (updatedNewFolderName !== newData[index].name) {
              await renameItem({ itemId: item.key, name: updatedNewFolderName });
            } else {
              shouldUpdate = false;
            }

            newData[index].name = updatedNewFolderName;
          } else {
            // if user presses enter without changing the name
            if (newFolderName !== newData[index].name) {
              await renameItem({ itemId: item.key, name: newFolderName });
            } else {
              shouldUpdate = false;
            }
            newData[index].name = newFolderName;
          }
          newData[index].editable = false;
          setData(newData);
          setSelectedRowKeys([]);
          setEditingKey('');
          setNewFolderName('');
          // only update if the name is changed
          if (shouldUpdate) {
            await onUpdate(null);
          }
        } catch (error) {}
      }
    }
  };
  useEffect(() => {
    if (editingKey && inputRef?.current) {
      (inputRef.current as any)?.focus(); // Focus the input when a new folder is added
    }
  }, [editingKey]);

  const addFolder = () => {
    if (editingKey) return;
    const newData = {
      key: randomUUID(),
      name: '',
      type: 'folder',
      editable: true,
      custom: true,
    };
    setData([newData, ...data]);
    edit(newData);
  };

  const onRename = (selectedRowData: any) => {
    if (editingKey) return;
    // set the editable true for the selected folder
    try {
      const newData = [...data];
      const item = newData.find((item: any) => item.key === selectedRowData.key);
      setErrorMessage('');
      item.editable = true;
      setData(newData);
      const isFile = selectedRowData?.type === 'file';
      isFile
        ? setNewFolderName(item.name.split('.').slice(0, -1).join('.'))
        : setNewFolderName(item.name);

      edit(selectedRowData);
    } catch (error) {
      console.log('error', error);
    }
  };

  const onMoveSelection = async (folderId: string | null) => {
    try {
      setErrorMessage('');

      setSelectOnMove(false);
      const selectedRowData =
        itemToMove ?? data.find((item: any) => item.key === selectedRowKeys[0]);
      if (!selectedRowData) {
        notification.error({ message: t('dashboard.fileList.moveError') });
        return;
      }
      const isFile = selectedRowData?.type === 'file';
      const moveItem = isFile ? moveFile : moveFolder;
      await moveItem({ itemId: selectedRowData.key, parentFolderId: folderId });
      message.success(
        t('dashboard.fileList.moveSuccess', { name: `${isFile ? 'File' : 'Folder'}` }),
      );
      setItemToMove(null);
      await onUpdate(null);
    } catch (error) {
      setItemToMove(null);
      message.error(t('dashboard.fileList.moveError'));
    }
  };

  const onMoveStart = async () => {
    setSelectOnMove(true);
  };

  const onMoveCancel = () => {
    setItemToMove(null);
    setSelectOnMove(false);
  };
  // create a function getAction call which returns the action on click of the button
  const handleAction = async (action: string, clickedRow?: any) => {
    const selectedRowData = clickedRow ?? data.find((item: any) => item.key === selectedRowKeys[0]);
    if (!selectedRowData) return;
    switch (action) {
      case 'open-file':
        // const selectedItemForAction = data.find((item: any) => item.key === selectedRowKeys[0]);
        if (selectedRowData.type === 'folder') {
          handleFolderClick(selectedRowData);
        } else {
          handleFileClick(selectedRowData);
        }

        break;
      case 'delete':
        deleteFileConfirmation(selectedRowData);
        break;
      case 'download':
        await onDownload(selectedRowData);
        break;
      case 'move':
        setItemToMove(selectedRowData);
        await onMoveStart();
        break;
      case 'copy':
        try {
          const isFile = selectedRowData.type === 'file';
          const copyItem = isFile ? copyFile : copyFolder;
          await copyItem({ itemId: selectedRowData.key, parentFolderId: currentFolderId });
          message.success(
            t('dashboard.fileList.copySuccess', { name: `${isFile ? 'File' : 'Folder'}` }),
          );
          await onUpdate(null);
          return;
        } catch (error) {
          message.error(t('dashboard.fileList.copyError'));
        }

        break;
      case 'rename':
        onRename(selectedRowData);
        break;
      default:
        break;
    }
  };

  const handleRightClickMenuAction = (action: string) => {
    handleAction(action, { ...contextMenu.record });
  };

  useEffect(() => {
    setData(fileList);
  }, [fileList.length, fileList]);
  const findCompletedJobStatus = (id: string) => {
    return completedJobs.find((job: any) => job.id === id)?.status;
  };
  const SUPORTED_FILE_FORMATS = ['dwg', 'dwt', 'dws', 'dxf', 'dwf', 'dgn', 'ifc', 'rvt'];
  const getFileIcon = (name: string) => {
    if (!name) return;
    if (SUPORTED_FILE_FORMATS.includes(name.toLowerCase().split('.').slice(-1).join('.'))) {
      return <SupportedFileIcon />;
    }
    return <UnsupportedFileIcon />;
  };

  const sendMarkStarred = async (record: any) => {
    try {
      await postMarkStarred([{ itemId: record.key, itemType: record.type }]);
    } catch (error) {
      console.log('error', error);
    }
  };

  const sendUnmarkStarred = async (record: any) => {
    try {
      await postUnMarkStarred([{ itemId: record.key, itemType: record.type }]);
    } catch (error) {
      console.log('error', error);
    }
  };
  // Function to toggle starred status
  const toggleStar = (record: any) => {
    if (starred.includes(record.key)) {
      setStarred(starred.filter((id: string) => id !== record.key));
      sendUnmarkStarred(record).then(() => {
        if (refetchStarredFiles) {
          refetchStarredFiles();
        }
      });
    } else {
      setStarred([...starred, record.key]);
      sendMarkStarred(record).then(() => {
        if (refetchStarredFiles) {
          refetchStarredFiles();
        }
      });
    }
  };

  const columns: Array<ColumnType<any>> = [
    {
      title: t('dashboard.fileList.columns.name'),
      showSorterTooltip: false,
      dataIndex: 'name',
      key: 'name',
      width: '27vw',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) =>
        record.editable === true ? (
          <Space>
            <Input
              ref={inputRef}
              autoFocus
              value={newFolderName}
              placeholder="folder-name"
              onPressEnter={(event) => {
                if (!errorMessage) save(record.key);
              }}
              onChange={(e) => {
                setErrorMessage('');
                setNewFolderName(e.target.value);
              }}
              style={{ borderColor: '#E43D4F' }}
              onBlur={() => {
                if (!errorMessage) save(record.key);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Escape') {
                  cancel();
                }
              }}
            />
            {/* show errorMessage in red */}
            <Typography.Text type="danger">{errorMessage}</Typography.Text>
          </Space>
        ) : (
          <Space
            direction="vertical"
            size={1}
            onMouseEnter={() => setHoveredRowKey(record.key)} // Set hovered row
            onMouseLeave={() => setHoveredRowKey(null)} // Clear hovered row
          >
            <Space
              onMouseEnter={() => setHoveredRowKey(record.key)} // Set hovered row
              onMouseLeave={() => setHoveredRowKey(null)} // Clear hovered row
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                // gap: '100%',
                minWidth: '25vw',
              }}
            >
              <Button
                title={
                  record.type === 'file' &&
                  isConvertedFileType(record?.name) &&
                  (!findCompletedJobStatus(record.key) ||
                    findCompletedJobStatus(record.key) === 'failed')
                    ? t('dashboard.jobProcessFail')
                    : ''
                  // findCompletedJobStatus(record.key) === 'completed'
                  //   ? ''
                  //   : t('dashboard.jobProcessing')
                }
                disabled={
                  record.type === 'file' &&
                  isConvertedFileType(record?.name) &&
                  (!findCompletedJobStatus(record.key) ||
                    findCompletedJobStatus(record.key) === 'failed' ||
                    findCompletedJobStatus(record.key) === 'processing')
                }
                type="link"
                onClick={() => {
                  if (record.type === 'folder') {
                    handleFolderClick(record);
                  } else {
                    handleFileClick(record);
                  }
                }}
              >
                {record.type === 'folder' ? <FolderIcon /> : getFileIcon(record.name)}

                {text}
              </Button>
              {isStarred(record) ? (
                <StarFilled style={{ color: '#ffc107' }} onClick={() => toggleStar(record)} />
              ) : (
                // Show outlined star only on hover
                hoveredRowKey === record.key && (
                  <StarOutlined style={{ color: 'lightgrey' }} onClick={() => toggleStar(record)} />
                )
              )}
            </Space>
            {record.systemPathName && (
              <Typography.Text
                type="secondary"
                style={{
                  fontSize: 'small',
                  color: '#5A5A5A',
                  fontWeight: 'bold',
                  backgroundColor: '#f0f0f0',
                  padding: '2px 4px',
                  borderRadius: '4px',
                  textTransform: 'uppercase',
                  letterSpacing: '0.5px',
                }}
              >
                {`myDrive${record?.systemPathName?.split('/')?.slice(0, -2).join(' > ')}`}
              </Typography.Text>
            )}
          </Space>
        ),
    },
    {
      title: t('dashboard.fileList.columns.lastModified'),
      showSorterTooltip: false,
      dataIndex: 'modifiedDateTime',
      key: 'lastModified',
      defaultSortOrder: 'descend' as SortOrder,
      sorter: (a, b) => {
        if (a.type === 'folder') return 1;
        if (b.type === 'folder') return -1;
        return moment(a.modifiedDateTime).unix() - moment(b.modifiedDateTime).unix();
      },
      render: (text: string) => {
        if (i18n.language === 'en') {
          // return Aug, 12 2021 12:00
          return moment(text)?.local()?.format('MMM DD, YYYY HH:mm');
        } else {
          return moment(text)?.local()?.format('YYYY-MM-DD HH:mm');
        }
      },
    },
    {
      title: t('dashboard.fileList.columns.format'),
      showSorterTooltip: false,
      dataIndex: 'mimeType',
      key: 'mimeType',
      sorter: (a, b) => {
        const firstFormat = a.mimeType?.split('/')[1] || '';
        const secondFormat = b.mimeType?.split('/')[1] || '';
        return firstFormat.localeCompare(secondFormat);
      },
      render: (text, record) => {
        if (!text) return '';
        return record?.name?.split ? record?.name?.split('.').slice(-1).join('.') : '';
      },
    },
    {
      title: t('dashboard.fileList.columns.size'),
      showSorterTooltip: false,
      dataIndex: 'size',
      key: 'size',
      sorter: (a, b) => a.size - b.size,
      // change to the appropriate unit notation
      render: (text) => {
        if (!text) return '';

        if (text < 1024) {
          return `${text} B`;
        } else if (text < 1024 * 1024) {
          return `${(text / 1024).toFixed(2)} KB`;
        } else if (text < 1024 * 1024 * 1024) {
          return `${(text / (1024 * 1024)).toFixed(2)} MB`;
        } else {
          return `${(text / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
      },
    },
  ];
  useImperativeHandle(ref, () => ({
    addFolder: addFolder,
  }));

  const actionsList = [
    {
      key: 'open-file',
      title: t('dashboard.actionBar.open'),
      icon: <FolderOpenOutlined />,
    },
    {
      key: 'rename',
      title: t('dashboard.actionBar.rename'),
      icon: <RenameIcon />,
    },
    {
      key: 'copy',
      title: t('dashboard.actionBar.copy'),
      icon: <CopyOutlined />,
    },
    {
      key: 'move',
      title: t('dashboard.actionBar.move'),
      icon: <MoveFolderIcon />,
    },
    {
      key: 'delete',
      title: t('dashboard.actionBar.delete'),
      icon: <DeleteOutlined />,
    },
    {
      key: 'download',
      title: t('dashboard.actionBar.download'),
      icon: <DownloadOutlined />,
    },
    {
      key: 'share',
      title: t('dashboard.actionBar.share'),
      icon: <ShareIcon />,
      disabled: true,
    },
  ];
  const getDisabledActions = (): any => {
    if (!selectedRowKeys || selectedRowKeys.length === 0) {
      return ['delete', 'download', 'move', 'copy', 'rename', 'open-file'];
    }
    const selectedRecord = data.find((item: any) => item.key === selectedRowKeys[0]);
    if (!selectedRecord) return ['delete', 'download', 'move', 'copy', 'rename', 'open-file'];

    if (selectedRecord?.type === 'folder') {
      return ['open-file', 'download'];
    }

    const isopenFileDisabled =
      selectedRecord?.type === 'file' &&
      isConvertedFileType(selectedRecord?.name) &&
      (!findCompletedJobStatus(selectedRecord?.key) ||
        findCompletedJobStatus(selectedRecord?.key) === 'failed' ||
        findCompletedJobStatus(selectedRecord.key) === 'processing');
    if (isopenFileDisabled) {
      return ['open-file'];
    }
    return [];
  };

  const getDisabledActionsForRightClickMenu = () => {
    // Disable the actions based on the contextMenu.record
    if (!contextMenu || !contextMenu.record) {
      return actionsList.map((action) => action.key);
    }
    const selectedRecord = contextMenu.record;
    if (selectedRecord?.type === 'folder') {
      return ['open-file', 'download'];
    }
    const isopenFileDisabled =
      selectedRecord?.type === 'file' &&
      isConvertedFileType(selectedRecord?.name) &&
      (!findCompletedJobStatus(selectedRecord?.key) ||
        findCompletedJobStatus(selectedRecord?.key) === 'failed' ||
        findCompletedJobStatus(selectedRecord.key) === 'processing');
    if (isopenFileDisabled) {
      return ['open-file'];
    }
    return [];
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Close the menu if the click occurs outside the context menu
      setContextMenu(null);
    };

    const handleEscKeyPress = (event: any) => {
      // Close the menu when the ESC key is pressed
      if (event.key === 'Escape') {
        setContextMenu(null);
      }
    };

    if (contextMenu) {
      // Add event listeners when the context menu is open
      window.addEventListener('click', handleOutsideClick);
      window.addEventListener('keydown', handleEscKeyPress);
    }

    return () => {
      // Clean up the event listeners when the context menu is closed
      window.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [contextMenu]);

  const handleMenuClick = (key: any) => {
    // setSelectedRowKeys([]);
    handleRightClickMenuAction(key);
    setContextMenu(null); // Close the context menu after click
  };

  const onRow = (record: any) => ({
    onContextMenu: (event: any) => {
      event.preventDefault();

      if (selectedRowKeys.length > 0 && selectedRowKeys[0] !== record.key) {
        setSelectedRowKeys([record.key]);
      }
      setContextMenu({
        record,
        position: { x: event.clientX, y: event.clientY },
      });
    },
  });

  const rightClickMenu = (
    <Menu onClick={(e) => handleMenuClick(e.key)}>
      {actionsList
        .filter((action) => getDisabledActionsForRightClickMenu().indexOf(action.key) === -1)
        .map((action) => (
          <Menu.Item key={action.key} disabled={action.disabled}>
            <Space>
              {action.icon}
              <span>{action.title}</span>
            </Space>
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '2em',
            zIndex: 9999,
          }}
        >
          Drop here
        </div>
      )}
      {!disableActions && selectedRowKeys.length === 1 && (
        <ActionBar
          selectedCount={selectedRowKeys.length}
          setSelectedAction={setSelectedAction}
          selectedAction={selectedAction}
          performAction={handleAction}
          disabledActions={getDisabledActions()}
        />
      )}
      <Loader
        isLoading={
          deleteFolderLoading ||
          deleteFileLoading ||
          folderCreationLoading ||
          renameFolderLoading ||
          renameFileLoading ||
          moveFolderLoading ||
          moveFileLoading ||
          copyFolderLoading ||
          copyFileLoading ||
          unstarredLoading ||
          starredLoading
        }
        children={null}
      />
      <FolderNavigation
        isVisible={selectOnMove}
        onCancel={onMoveCancel}
        onMove={onMoveSelection}
        itemName={itemToMove?.name}
        itemid={itemToMove?.key}
        onAddNewFolder={addFolder}
        actionName={'move'}
      />
      <Table
        dataSource={data}
        columns={columns}
        rowSelection={rowSelection}
        pagination={{
          pageSize: 10,
          position: ['bottomCenter'],
        }}
        onRow={onRow}
      />
      {/* Render the menu at the position of right-click */}
      {contextMenu && (
        <div
          style={{
            position: 'absolute',
            top: contextMenu.position.y,
            left: contextMenu.position.x,
            zIndex: 1000,
            border: '1px solid #d9d9d9',
            backgroundColor: '#fff',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          }}
        >
          {!disableActions && rightClickMenu}
        </div>
      )}
      <DownloadNotification downloads={downloads} />
    </div>
  );
});

export default FileList;
