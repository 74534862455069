import { ReactComponent as RenameSvg } from './rename-icon.svg'; // The path to your svg file
import { ReactComponent as MoveFolderSvg } from './move-folder.svg'; // The path to your svg file
import { ReactComponent as ShareSvg } from './share-svg.svg'; // The path to your svg file
import { ReactComponent as FolderSvg } from './folder.svg'; // The path to your svg file
import { ReactComponent as SupportedFileSvg } from './supported_file.svg'; // The path to your svg file
import { ReactComponent as UnsupportedFileSvg } from './unsupported_file.svg'; // The path to your svg file

export const RenameIcon = () => {
	return <RenameSvg />;
};

export const MoveFolderIcon = () => {
	return <MoveFolderSvg />;
};

export const ShareIcon = () => {
	return <ShareSvg />;
};
export const FolderIcon = () => {
	return <FolderSvg />;
};

export const SupportedFileIcon = () => {
	return <SupportedFileSvg />;
};

export const UnsupportedFileIcon = () => {
	return <UnsupportedFileSvg />;
};

