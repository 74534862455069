import React from 'react';
import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';

export type DownloadItem = {
  id: string;
  fileName: string;
  progress: number;
};

const DownloadNotifications = ({ downloads }: { downloads: DownloadItem[] }) => {
  const { t } = useTranslation();
  if (downloads.length === 0) return null; // Only show if there are downloads

  return (
    <div style={{ position: 'fixed', right: 20, bottom: 20, width: 300, zIndex: 1000 }}>
      {downloads.map((download) => (
        <div
          key={download.id}
          style={{
            padding: 10,
            backgroundColor: '#f0f2f5',
            borderRadius: 5,
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
            marginBottom: 10,
          }}
        >
          <div style={{ fontWeight: 'bold' }}>{download.fileName}</div>

          <Progress percent={parseFloat(download.progress?.toFixed(0))} />
          {download.progress === 100 && <div>{t('dashboard.downloadCompleteNotification')}</div>}
        </div>
      ))}
    </div>
  );
};

export default DownloadNotifications;
